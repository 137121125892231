import styled from "styled-components"
import {
  SectionHeading,
  SectionSubHeading,
  Commonh6,
  Commonpara,
} from "../Common/common.style"
import { device } from "../Common/device"
import { SocialFacebook } from "@styled-icons/typicons/SocialFacebook"
import { SocialTwitter } from "@styled-icons/typicons/SocialTwitter"
import { SocialLinkedin } from "@styled-icons/typicons/SocialLinkedin"
import { SocialInstagram } from "@styled-icons/typicons/SocialInstagram"

export const TeamSection = styled.section`
  padding: 100px 0px;

  @media ${device.tablet} {
    padding: 80px 10px;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 45px;

  @media ${device.tablet} {
    margin-bottom: 35px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 10px;
`

export const SubHeading = styled(SectionSubHeading)``

export const TeamSlider = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`

export const TeamSliderWrapper = styled.div`
  outline: 0;

  :focus {
    outline: 0;
  }
`

export const TeamSliderLayout = styled.div`
  margin: 15px;
`

export const TeamImgFigure = styled.figure`
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  width: 100%;

  :hover .teamImg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .teamImg {
    height: 320px;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out !important;
    -webkit-transition: -webkit-transform 0.3s ease-in-out !important;
  }
`

export const TeamInnerLayout = styled.div`
  position: relative;
`

export const TeamSocialLayout = styled.div`
  text-align: center;
`

export const TeamName = styled(Commonh6)`
  text-align: center;
  background: #fff;
  line-height: 1;
  padding: 10px 15px;
  margin-bottom: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: -40px;
  left: -1px;
  font-weight: 700;

  @media ${device.mobileXL} {
    line-height: 1;
  }
`

export const TeamDesignation = styled(Commonpara)`
  color: #fff;
  background: #bfd9b3;
  margin-bottom: 0px;
  position: absolute;
  bottom: 20px;
  padding: 5px 10px;
  right: 0px;
`

export const TeamSocial = styled.a`
  display: inline-block;

  :hover svg {
    transform: rotate(30deg);
    transition: all 0.5s;
  }
`

export const FbIcon = styled(SocialFacebook)`
  width: 35px;
  color: #000;
  padding: 5px;
  border-radius: 100%;
  margin: 10px 3px;
  transition: all 0.5s;
`

export const TwitterIcon = styled(SocialTwitter)`
  width: 35px;
  color: #000;
  padding: 5px;
  border-radius: 100%;
  margin: 10px 3px;
  transition: all 0.5s;
`

export const LinkedinIcon = styled(SocialLinkedin)`
  width: 35px;
  color: #000;
  padding: 5px;
  border-radius: 100%;
  margin: 10px 3px;
  transition: all 0.5s;
`

export const InstaIcon = styled(SocialInstagram)`
  width: 35px;
  color: #000;
  padding: 5px;
  border-radius: 100%;
  margin: 10px 3px;
  transition: all 0.5s;
`

export const NextPrev = styled.div`
  text-align: center;
  line-height: 0px;
`

export const ImgButton = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  margin: 0px 5px;
  cursor: pointer;
  height: 24px;
  transition: all 0.4s;

  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`

export const NextImg = styled.img`
  margin: 0px 5px;
  cursor: pointer;
  transition: all 0.4s;
  height: 24px;

  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`
