import styled from "styled-components"
import { device } from "../Common/device"
import { Navbar } from "react-bootstrap"

import { PhoneCall } from "@styled-icons/boxicons-solid/PhoneCall"
import { Mail } from "@styled-icons/heroicons-solid/Mail"

export const NavbarCustom = styled(Navbar)`
  background: ${props =>
    props.isSticky ? "#ffffff" : "rgb(191, 217, 179, 0.8)"} !important;
  padding: 0px !important;
  box-shadow: ${props =>
    props.isSticky ? "0px 1px 11px -1px #d6d6d6" : "none"};

  @media ${device.laptop} {
    .navbar-collapse {
      background: #fff !important;
      padding: 10px !important;
    }
  }
  @media ${device.mobileXL} {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  //Scrollspy ul styles
  ul {
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
    display: inline-flex;

    @media ${device.laptop} {
      display: block;
    }
  }

  //Anchor Link Styles
  a {
    padding: 15px 20px;
    color: #494949;

    :hover {
      text-decoration: none;
      color: ${props => (props.isSticky ? "#bfd9b3" : "#ffffff")};
    }

    @media ${device.laptop} {
      color: #494949;
      display: block;
    }
  }

  .contactBtn {
    background: ${props => (props.isSticky ? "#bfd9b3" : "#ffffff")};
    color: ${props => (props.isSticky ? "#ffffff" : "#bfd9b3")};
    padding: 6px 40px;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 20px;

    :hover {
      background: ${props => (props.isSticky ? "#ffffff" : "#bfd9b3")};
      color: ${props => (props.isSticky ? "#bfd9b3" : "#ffffff")};
      border: ${props =>
        props.isSticky ? "1px solid #bfd9b3" : "1px solid #ffffff"};
      text-decoration: none;
    }

    @media ${device.laptop} {
      margin: 15px 20px;
    }
  }
`

export const BrandLogo = styled.img`
  margin-bottom: 0px;
  max-width: ${props => (props.isSticky ? "140px" : "175px")};
  transition: all 0.5s;

  @media ${device.mobileM} {
    max-width: 145px;
  }
`

export const EmailIcon = styled(Mail)`
  width: 30px;
  margin-right: 10px;
`

export const PhoneCallIcon = styled(PhoneCall)`
  width: 30px;
  margin-right: 10px;
`

export const NavbarToggleCustom = styled(Navbar.Toggle)`
    outline:0px !important;

    border-color:${props =>
      props.isSticky ? "rgba(0,0,0,.1)" : "rgba(255,255,255,.1)"} !important;
    .navbar-toggler-icon{
    background-image:url("${props =>
      props.isSticky
        ? "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"
        : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"}") !important;
    }
`
