import styled from "styled-components"
import {
  SectionHeading,
  SectionSubHeading,
  Commonh4,
  Commonh5,
  Commonh6,
  Commonpara,
} from "../Common/common.style"
import { device } from "../Common/device"
import { TabList } from "react-tabs"
import { ChevronLeft } from "@styled-icons/fa-solid/ChevronLeft"
import { ChevronRight } from "@styled-icons/fa-solid/ChevronRight"
import { Row } from "react-bootstrap"

export const PropertiesSection = styled.section`
  padding: 100px 0px 85px;
  background: #f5f5f5;

  @media ${device.tablet} {
    padding: 80px 10px 65px;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 40px;
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 10px;
`

export const SubHeading = styled(SectionSubHeading)``

export const CustomTabList = styled(TabList)`
  margin: 0;
  margin-bottom: 15px;
  text-align: center;

  .react-tabs__tab {
    font-size: 16px;
    color: #6f6f6f;
    margin-right: 10px;
    border: none;
    margin-bottom: 10px;
    padding: 5px 40px;
    border-radius: 20px;
    border: 1px solid #bfd9b3;

    :focus {
      box-shadow: none;
    }
  }

  .react-tabs__tab:focus:after {
    background: transparent;
  }

  .react-tabs__tab--selected {
    background: #bfd9b3;
    border: none;
    border-radius: 20px;
    border: 1px solid #bfd9b3;
    color: #fff;
  }
`

export const SliderOuterWrapper = styled.div`
  margin: 0px -15px;
  position: relative;
`

export const ImgButtonLeft = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 99;
  background: #bfd9b3;
  padding: 10px 15px;
  left: -10px;

  :focus {
    outline: 0;
  }

  :hover {
    background: #d8e302;
  }

  @media ${device.mobileXL} {
    padding: 10px;
    left: 0px;
  }
`

export const LeftIcon = styled(ChevronLeft)`
  width: 20px;
  height: 20px;
  color: #fff;
`

export const PropertiesSliderWrapper = styled.div`
  outline: 0;

  :focus {
    outline: 0;
  }
`

export const PropertyLayout = styled.div`
  box-shadow: 0px 0px 15px 1px #ccc;
  background: #fff;
  margin: 15px;
`

export const CustomRow = styled(Row)`
  align-items: center;
`

export const ImageHolder = styled.div`
  .propertyImg {
    height: 420px;
  }
`

export const TextLayout = styled.div`
  padding: 40px 40px 40px 10px;

  @media ${device.laptop} {
    padding: 40px;
  }
`

export const TabHeading = styled(Commonh4)`
  line-height: 1;
  text-align: left;

  @media ${device.tablet} {
    line-height: 1;
  }
`

export const TabPara1 = styled(Commonpara)``

export const SizeText = styled(Commonh5)`
  line-height: 1;
  text-align: left;
  margin-bottom: 0;
  @media ${device.mobileXL} {
    line-height: 1;
  }
`

export const SpecLayout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const SpecIcon = styled.img`
  width: 40px;
`

export const SpecText = styled(Commonh6)`
  margin-bottom: 0px;
  margin-left: 10px;
`

export const ImgButtonRight = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 99;
  background: #bfd9b3;
  padding: 10px 15px;
  right: -10px;

  :focus {
    outline: 0;
  }

  :hover {
    background: #d8e302;
  }

  @media ${device.mobileXL} {
    padding: 10px;
    right: 0px;
  }
`

export const RightIcon = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  color: #fff;
`
