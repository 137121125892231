import styled from "styled-components"
import {
  SectionHeading,
  SectionSubHeading,
  Commonh5,
  Commonpara,
} from "../Common/common.style"
import { device } from "../Common/device"
import "bootstrap/dist/css/bootstrap.min.css"
import { QuoteLeft } from "@styled-icons/fa-solid/QuoteLeft"
import BackgroundImg from "../../../assets/realestate-5-images/about-section-bg.jpg"

export const TestimonialsSection = styled.section`
  background-image: url(${BackgroundImg}) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const SectionWrapper = styled.div`
  padding: 100px 0px;

  @media ${device.tablet} {
    padding: 80px 10px;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 45px;

  @media ${device.tablet} {
    margin-bottom: 35px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 10px;
`

export const SubHeading = styled(SectionSubHeading)``

export const SliderOuterWrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`

export const TestimonialSliderWrapper = styled.div`
  outline: 0;

  :focus {
    outline: 0;
  }
`

export const TestimonialSliderLayout = styled.div`
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 15px 0px #d0d0d0;
  border-left: 5px solid #bfd9b3;
  margin: 15px;
  display: flex;
  align-items: center;

  @media ${device.mobileXL} {
    flex-direction: column;
  }
`

export const AuthorImageHolder = styled.div`
  width: 100px;
  flex-shrink: 0;

  @media ${device.mobileXL} {
    margin-bottom: 20px;
  }

  .profileImg {
    width: 100px;
    border-radius: 100%;
  }
`

export const TestimonialTextLayout = styled.div`
  margin-left: 20px;

  @media ${device.mobileXL} {
    margin-left: 0px;
  }
`

export const ProfileLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const ProfileNameLayout = styled.div``

export const ProfileName = styled(Commonh5)`
  margin-bottom: 5px;
  text-align: left;
  line-height: 1;

  @media ${device.tablet} {
    line-height: 1;
  }
`

export const QuotesIcon = styled(QuoteLeft)`
  width: 40px;
  color: #bfd9b3;
  margin-bottom: 10px;
`

export const Designation = styled(Commonpara)`
  margin-bottom: 0px;
  line-height: 1;
`

export const Testimonial = styled(Commonpara)`
  margin-bottom: 0;
`

export const NextPrev = styled.div`
  text-align: center;
  line-height: 0px;
`

export const ImgButton = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  margin: 15px 5px 0px;
  cursor: pointer;
  height: 24px;
  transition: all 0.4s;

  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`

export const NextImg = styled.img`
  margin: 15px 5px 0px;
  cursor: pointer;
  transition: all 0.4s;
  height: 24px;

  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`
