import styled from "styled-components"
import { device } from "../Common/device"
import { SectionHeading, SectionSubHeading } from "../Common/common.style"
import { ChevronLeft } from "@styled-icons/fa-solid/ChevronLeft"
import { ChevronRight } from "@styled-icons/fa-solid/ChevronRight"

export const GallerySection = styled.section`
  padding: 100px 0px 0px;
  background: #f5f5f5;

  @media ${device.tablet} {
    padding: 80px 0px 0px;
  }
`

export const GalleryLayout = styled.div`
  position: relative;

  .slick-list {
    line-height: 0;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 60px;

  @media ${device.tablet} {
    margin-bottom: 50px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 10px;
`

export const SubHeading = styled(SectionSubHeading)``

export const ImgButtonLeft = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  padding: 15px;
  top: calc(50% - 25px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  left: 0px;

  :focus {
    outline: 0;
  }
`

export const LeftIcon = styled(ChevronLeft)`
  width: 20px;
  height: 20px;
  color: #fff;
`

export const GalleryImageWrapper = styled.figure`
  margin-bottom: 0px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  .galleryImg {
    height: 375px;
    margin-right: 3px;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out !important;
    -webkit-transition: -webkit-transform 0.3s ease-in-out !important;

    :hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    @media ${device.mobileL} {
      margin-right: 0px;
    }
  }
`

export const ImgButtonRight = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  padding: 15px;
  top: calc(50% - 25px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  right: 0px;

  :focus {
    outline: 0;
  }
`

export const RightIcon = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  color: #fff;
`
