import styled from "styled-components"
import { device } from "./device"

export const Commonbtn = styled.a`
  background: #bfd9b3;
  color: #fff;
  padding: 10px 40px;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    background: #fff;
    color: #bfd9b3;
    border: 1px solid #bfd9b3;
    text-decoration: none;
  }

  @media ${device.mobileXL} {
    padding: 10px 30px;
  }
`

export const Commonh1 = styled.h1`
  font-size: 70px;
  line-height: 76px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.laptop} {
    font-size: 54px;
    line-height: 60px;
  }

  @media ${device.tablet} {
    font-size: 46px;
    line-height: 52px;
  }

  @media ${device.mobileXL} {
    font-size: 36px;
    line-height: 42px;
  }
`

export const Commonh2 = styled.h2`
  font-size: 50px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.tablet} {
    font-size: 46px;
    line-height: 52px;
  }
`

export const Commonh3 = styled.h3`
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.laptopM} {
    font-size: 34px;
    line-height: 40px;
  }

  @media ${device.mobileXL} {
    font-size: 30px;
    line-height: 36px;
  }
`

export const Commonh4 = styled.h4`
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 32px;
  }
`

export const Commonh5 = styled.h5`
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.tablet} {
    font-size: 22px;
    line-height: 28px;
  }
`

export const Commonh6 = styled.h6`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;

  @media ${device.mobileXL} {
    font-size: 17px;
    line-height: 23px;
  }
`

export const Commonpara = styled.p`
  color: #6f6f6f;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 23px;
`

export const SectionHeading = styled(Commonh3)`
  line-height: 1;
  margin-bottom: 10px;

  @media ${device.laptopM} {
    line-height: 1;
  }

  @media ${device.mobileXL} {
    line-height: 1;
  }
`

export const SectionSubHeading = styled(Commonpara)`
  line-height: 1;
  text-align: center;
`
