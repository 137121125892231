import styled from "styled-components"
import { Commonh1, Commonh6 } from "../Common/common.style"
import { device } from "../Common/device"
import { ChevronLeft } from "@styled-icons/fa-solid/ChevronLeft"
import { ChevronRight } from "@styled-icons/fa-solid/ChevronRight"

export const BannerSection = styled.section`
  min-height: 100vh;
`

export const BannerSliderWrapper = styled.div`
  position: relative;
  .slick-list {
    line-height: 0;
  }
`

export const BannerSliderLayout = styled.div`
  position: relative;

  // ANIMATIONS
  @-webkit-keyframes leaves {
    0% {
      -webkit-transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
    }
  }

  @keyframes leaves {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`

export const BannerImageHolder = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;

  .bannerImage {
    min-height: 100vh;
    -webkit-animation: leaves 8s ease-in-out infinite alternate;
    animation: leaves 8s ease-in-out infinite alternate;
  }
`

export const BannerContents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  line-height: 1.5;
  max-width: 750px;
  margin: 50px auto 0px;

  @media ${device.laptop} {
    max-width: 600px;
  }

  @media ${device.tablet} {
    max-width: 500px;
    margin: 30px auto 0px;
  }

  @media ${device.mobileXL} {
    max-width: 400px;
  }
`

export const BannerTextLayout = styled.div`
  padding: 60px;
  text-align: center;
  background-color: rgb(191, 217, 179, 0.8);

  @media ${device.laptop} {
    width: 100%;
    padding: 10px;
  }
`

export const BannerHeading = styled(Commonh1)`
  color: #000;
`

export const BannerSubHeading = styled(Commonh6)`
  color: #000;
`

export const ImgButtonLeft = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  left: 0px;

  :focus {
    outline: none;
  }

  @media ${device.mobileXL} {
    padding: 15px;
    bottom: 15px;
    top: unset;
    left: unset;
    right: 50%;
    margin-right: 5px;
  }
`

export const LeftIcon = styled(ChevronLeft)`
  width: 25px;
  height: 25px;
  color: #fff;
`

export const ImgButtonRight = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  right: 0px;

  :focus {
    outline: none;
  }

  @media ${device.mobileXL} {
    padding: 15px;

    bottom: 15px;
    top: unset;
    right: unset;
    left: 50%;
    margin-left: 5px;
  }
`

export const RightIcon = styled(ChevronRight)`
  width: 25px;
  height: 25px;
  color: #fff;
`
